@import "../styles/variables.scss";

.faq {
    background-image: url(../assets/images/backgrounds/halftone-a.jpg);
}

.faqContainer {
    background: white;
    border: 8px solid $border-color;
    border-radius: 24px;
    box-shadow: -3px 3px 0 0 $border-color;
    padding: 15px;
    margin-bottom: 2rem;
}