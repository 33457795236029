@import "../styles/variables.scss";

.story {
    text-align: center;
    background-image: url(../assets/images/backgrounds/retro-c.jpg);
}

.storyContainer {
    background: white;
    border: 8px solid $border-color;
    border-radius: 24px;
    box-shadow: -3px 3px 0 0 $border-color;
    padding-top: 15px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.storyText {
    padding: 0 10px 0 10px;
}

.storyImage {
    max-width: 100%;
    border-radius: 0px 0px 15px 15px;
}

.button {
    font-size: 24px;
    height: 52px;
    margin-bottom: 20px;
}