@import "../styles/variables.scss";

.roadmap {
    text-align: center;
    background-image: url(../assets/images/backgrounds/galaxy.jpg);
    color: #fff;
}

.timeline {
    position: relative;
    margin: 0 auto 1rem auto;
    overflow: hidden;
}
.timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    border-right: 2px dashed #fff;
    height: 100%;
    display: block;
}

.timelineRow {
    padding-left: 50%;
    position: relative;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.timelineRow .timelineTime {
    position: absolute;
    right: 50%;
    top: 15px;
    text-align: right;
    margin-right: 20px;
    color: #fff;
    font-size: 1.5rem;
}
.timelineRow .timelineTime small {
    display: block;
    font-size: 0.8rem;
}
.timelineRow .timelineContent {
    position: relative;
    padding: 20px 30px;
    background: #1a233a;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.timelineRow .timelineContent:after {
    content: "";
    position: absolute;
    top: 20px;
    height: 16px;
    width: 16px;
    background: #1a233a;
}
.timelineRow .timelineContent:before {
    content: "";
    position: absolute;
    top: 20px;
    right: -49px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    z-index: 10;
    background: #272e48;
    border: 2px solid #fff;
}

.timelineRow .timelineContentDone {
    @extend .timelineContent;
}

.timelineRow .timelineContentDone:before {
    @extend .timelineContent:before;
    background: rgb(28, 165, 28);
}

.timelineRow .timelineContentDone:after {
    @extend .timelineContent:after;
}

.timelineRow:nth-child(even) .timelineContent {
    margin-left: 40px;
    text-align: left;
}
.timelineRow:nth-child(even) .timelineContent:after {
    left: -8px;
    right: initial;
    border-bottom: 0;
    border-left: 0;
    transform: rotate(-135deg);
}
.timelineRow:nth-child(even) .timelineContent:before {
    left: -51px;
    right: initial;
}
.timelineRow:nth-child(odd) {
    padding-left: 0;
    padding-right: 50%;
}
.timelineRow:nth-child(odd) .timelineTime {
    right: auto;
    left: 50%;
    text-align: left;
    margin-right: 0;
    margin-left: 20px;
}
.timelineRow:nth-child(odd) .timelineContent {
    margin-right: 40px;
}
.timelineRow:nth-child(odd) .timelineContent:after {
    right: -8px;
    border-left: 0;
    border-bottom: 0;
    transform: rotate(45deg);
}

.roadmapLink {
    color: $social-icon-color;
    text-decoration: underline;
}

.roadmapLink:hover {
    color: $social-icon-hover-color;
}

.roadmapList {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;
}

.roadmapList>li {
    position: relative;
    padding-bottom: 4px;
}

.checkmark {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
    margin-top: 4px;
}

.checkmarkCompleted {
    @extend .checkmark;
    color: rgb(28, 165, 28);
}

.checkmarkTodo {
    @extend .checkmark;
    color: gray;
}

@media (max-width: 992px) {
    .timeline {
        padding: 15px;
    }
    .timeline:after {
        border: 0;
    }
    .timeline .timelineRow:nth-child(odd) {
        padding: 0;
    }
    .timeline .timelineRow:nth-child(odd) .timelineTime {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 10px 0;
    }
    .timeline .timelineRow:nth-child(odd) .timelineContent {
        margin: 0;
    }
    .timeline .timelineRow:nth-child(odd) .timelineContent:before {
        display: none;
    }
    .timeline .timelineRow:nth-child(odd) .timelineContent:after {
        display: none;
    }
    .timeline .timelineRow:nth-child(even) {
        padding: 0;
    }
    .timeline .timelineRow:nth-child(even) .timelineTime {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 10px 0;
        text-align: left;
    }
    .timeline .timelineRow:nth-child(even) .timelineContent {
        margin: 0;
    }
    .timeline .timelineRow:nth-child(even) .timelineContent:before {
        display: none;
    }
    .timeline .timelineRow:nth-child(even) .timelineContent:after {
        display: none;
    }
}