@import "../styles/variables.scss";

.about {
    background-image: url(../assets/images/backgrounds/gradient-red.jpg);
}

.container {
    margin-bottom: 80px;
}

.gifCol {
    align-self: center;
}
.gifCol img {
    border-radius: 5%;
    border: 3px solid #000;
}

.marquee {
    border-top: 3px solid #000 !important;
    border-bottom: 3px solid #000 !important;
}

.button {
    font-size: 24px !important;
    height: 52px !important;
}

@media only screen and (min-width: 768px) {
  .aboutText {
    padding-left: 70px !important;
  }
}

.cta {
    position: relative;
    top: 45px;
    text-align: center;
    font-size: 52px;
}

.socialIcon {
    margin-right: 20px;
    cursor: pointer;
    color: $social-icon-color;
}

.socialIcon:hover {
    color: $social-icon-hover-color;
}

@media only screen and (max-width: 992px) {
    .gifCol {
      max-width: 85% !important;
      margin: 0 auto 50px auto;
    }
  }