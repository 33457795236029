@import "./variables.scss";

* {
    box-sizing: border-box;
    margin: 0;
}

html {
  cursor: url('../assets/images/cursor.png'), default;
}

body {
  width: 100%;
  font-family: $Chewy;
  color: $dark;
}

h2 {
  font-size: 42px !important;
}

p, ul {
  font-family: $Montserrat;
  font-size: 20px !important;
}

ul {
  text-align: start;
}

#root {
  height: 100%;
}

.section {
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 80px;
}