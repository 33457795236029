@import "../styles/variables.scss";

.animatedArrow {
  font-size: 42px;
  color: $navlink-color;
  position: absolute;
  top: 96%;
  left: 50%;
  transform: translate(-50%,-50%);
  
  /*start animation*/
  animation-name: pippo;
  animation-duration: 2s ;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
  
@keyframes pippo {
  0% {top: 95%}
  100% {top: 97%}
}

.salsaFeature {
  margin: 0;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
    max-width: 1000px;
  }
}

.message {
  margin-bottom: 20px;
}

.speechBubble {
  position: relative;
  padding: 15px;
  color: #000;
  background: #fff;
  border-radius: 10px;
  max-width: 80vw;
  margin: 85px auto 0;
  @media (max-width: 991px) {
    margin: 85px auto 20px;
  }
}

/* creates triangle */
.speechBubble:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -16px;
  left: 50%;
  margin-left: -15px;
  width: 0;
  border-style: solid;
  border-color: #fff transparent;
  border-top-width: 35px;
  border-right-width: 15px;
  border-bottom-width: 0px;
  border-left-width: 15px;
  @media (min-width: 992px) {
    bottom: 16px;
    right: -32px;
    left: auto;
    margin-left: auto;
    width: 0;
    border-width: 0px 41px 15px 15px;
    border-style: solid;
    border-color: #fff transparent;
  }
}

.button {
  font-size: 24px !important;
  height: 52px !important;
  margin-right: 10px !important;
  margin-bottom: 10px;
  width: 190px;
}


.salsaImage {
  max-width: 50vw;
  @media (min-width: 992px) {
    max-width: 520px;
  }
}

@media (max-height: 500px) {
  .salsaImageContainer {
    display: none;
  }
  .speechBubble:after {
    display: none;
  }
}

@media (min-width: 992px) and (max-height: 600px) {
  .salsaImage {
    max-width: 442px;
  }
}