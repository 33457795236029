@import "../styles/variables.scss";

.rarity {
    text-align: center;
    background-image: url(../assets/images/backgrounds/pattern-blue-soft.jpg);
}

.rarityContainer {
    background: white;
    border: 8px solid $border-color;
    border-radius: 24px;
    box-shadow: -3px 3px 0 0 $border-color;
    padding-top: 15px;
}

.rarityImage {
    max-width: 100%;
}

.listGroup {
    font-size: 18px;
}

.optionalListGroup {
    @extend .listGroup;
    @include breakpoint(md) {
        padding-bottom: 20px;
    }
}