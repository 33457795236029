@import "../styles/variables.scss";

.footer {
    color: #fff;
    background: #6930A4;
}

.footer a {
    color: $social-icon-color;
    text-decoration: none;
    display: inline-block;
    margin: 0 0.5rem;
    font-size: 1.3rem;
}
.footer a:hover {
    color: $social-icon-hover-color;
}

.socialIcon {
    font-size: 3rem;
}

.heart {
    color: red;
}
