@import "../styles/variables.scss";

.team {
    background-image: url(../assets/images/backgrounds/pattern-yellow-soft.jpg);
}

.teamContainer {
    background: white;
    border: 8px solid $border-color;
    border-radius: 24px;
    box-shadow: -3px 3px 0 0 $border-color;
    padding-top: 15px;
}

.teamCard {
    border: none !important;
}

.teamCardTitle {
    font-size: 1.8rem !important;
}

.teamCardImage {
    margin: 0 0 1rem 1rem;
    float: right;
    max-width: 30%;
    border-radius: 20% !important;
    border: 3px solid #000;
}

.button {
    font-size: 24px;
    height: 52px;
}