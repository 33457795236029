@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

$Montserrat: 'Montserrat', sans-serif;
$Chewy: 'Chewy', cursive !important;

// colors
$dark: rgb(29, 29, 29);
$light: #fff;
$navlink-shadow: #000;
$navlink-color: #f8f8ff;
$navlink-hover-color: pink;
$primary: #1e88e5;
$primary-hover: #187bd1;
$box-shadow: #46bcff;
$border-color: #1f2024;
$social-icon-color: #fff;
$social-icon-hover-color: rgba(255, 255, 255, 0.75);

@mixin breakpoint($point) {
    @if $point == md {
        // 768px
        @media (min-width: 48em) {
            @content;
        }
    }
}