@import "../styles/variables.scss";

.utility {
    text-align: center;
    background-image: url(../assets/images/backgrounds/pattern-green-soft.jpg);
    background-size: contain;
}

.utilityContainer {
    background: white;
    border: 8px solid $border-color;
    border-radius: 24px;
    box-shadow: -3px 3px 0 0 $border-color;
    padding: 15px;
    height: 100%;
}

.utilityMerchContainer {
    background-image: url(../assets/images/merch.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 8px solid $border-color;
    border-left: none;
    border-right: none;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.utilityMerchCta {
    background: #fff;
    border: 8px solid $border-color;
    border-radius: 24px;
    box-shadow: -3px 3px 0 0 $border-color;
    padding: 15px;
    width: 500px;
    max-width: 95%;
}

.button {
    font-size: 24px;
    height: 52px;
}

.mobileAppBullets {
    font-size: 1.3rem;
}

.mobileAppImageContainer {
    position: relative;
}

.mobileAppImage {
    background: #fff;
    margin: 1rem auto 2rem;
    max-width: 100%;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    transition: opacity 1.5s ease-in-out;
}

.secondMobileAppImage {
    @extend .mobileAppImage;
    position: absolute;
}

.firstMobileAppImage {
    @extend .mobileAppImage;
    position: relative;
    animation-name: fadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 6s;
    animation-direction: alternate;
}

.salsaCommunityImage {
    margin: 2rem auto 2rem;
    max-width: 70%;
    -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
}

.salsaCommunityImage:hover {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

.utilityImage, .utilityImageBorder {
    max-width: 75%;
    margin: 0 auto;
}
.utilityImageBorder {
    border-radius: 5%;
    border: 2px solid #000;
}

.weWantYou {
    margin: 0 auto 2rem;
    max-width: 45%;
}

@media (max-width:991px) {
    .utilityImage,
    .utilityImageBorder {
        max-width: 45%;
        margin-top: 1rem;
    }

    .weWantYou {
        margin: 1rem auto 2rem;
        max-width: 55%;
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes fadeInOut {
    0% {
    opacity:1;
    }
    45% {
    opacity:1;
    }
    55% {
    opacity:0;
    }
    100% {
    opacity:0;
    }
}