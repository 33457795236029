@import "../styles/variables.scss";

.navbar {
    position: absolute !important;
    padding: 0 20px;
}

.brand {
    height: 100px;
    -webkit-filter: drop-shadow(0px 0px 3px #000);
    filter: drop-shadow(0px 0px 3px #000);
}

.links {
    font-size: 30px;
    color: $navlink-color;
}

.socials {
    font-size: 45px;
}

.social {
    color: $navlink-color !important;
}
.social path {
    stroke: $navlink-shadow;
    stroke-width: 1.2rem;
    stroke-linejoin: round;
}

.social:hover {
    color: $navlink-hover-color !important;
}

.link {
    color: $navlink-color !important;
    text-shadow: -1px -1px $navlink-shadow, 1px -1px $navlink-shadow, -1px 1px $navlink-shadow, 3px 3px $navlink-shadow, 0 0 15px rgba(255, 255, 255, 0.5);
}

.link:hover {
    color: $navlink-hover-color !important;
}

.linkAbout {
    @extend .link;
    @media (max-width: 991px) {
        background-image: url(../assets/images/backgrounds/gradient-red.jpg);
    }
}

.linkStory {
    @extend .link;
    @media (max-width: 991px) {
        background-image: url(../assets/images/backgrounds/retro-c.jpg);
    }
}

.linkRarity {
    @extend .link;
    @media (max-width: 991px) {
        background-image: url(../assets/images/backgrounds/pattern-blue-soft.jpg);
    }
}

.linkUtility {
    @extend .link;
    @media (max-width: 991px) {
        background-image: url(../assets/images/backgrounds/pattern-green-soft.jpg);
    }
}

.linkRoadmap {
    @extend .link;
    @media (max-width: 991px) {
        background-image: url(../assets/images/backgrounds/galaxy.jpg);
    }
}

.linkTeam {
    @extend .link;
    @media (max-width: 991px) {
        background-image: url(../assets/images/backgrounds/pattern-yellow-soft.jpg);
    }
}

.linkFaq {
    @extend .link;
    @media (max-width: 991px) {
        background-image: url(../assets/images/backgrounds/halftone-a.jpg);
    }
}

.open {
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.3);
}

.toggler {
    background: $navlink-color !important;
}

@media (max-width:991px) {
    .linksContainer {
        border: 3px solid #000 !important;
        border-radius: 5%;
        overflow: hidden;
    }
    .link {
        text-align: center;
        background-size: cover;
        border-bottom: 2px dashed #000;
    }
    .link:last-child {
        border-bottom: 0
    }
    .link:hover, .social:hover {
        color: #fff !important;
        background: $navlink-hover-color;
    }
    .links {
        font-size: 26px;
    }
    .socials {
        display: none !important;
    }
    .brand {
        height: 80px;
    }
}